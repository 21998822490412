var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c(
                "head-layout",
                {
                  attrs: {
                    "head-title": "基础信息",
                    "head-btn-options": _vm.headTopBtnOptions,
                  },
                  on: {
                    "head-goback": _vm.handleHeadGoback,
                    "head-save": _vm.headSave,
                  },
                },
                [
                  _c("template", { slot: "titleSuffix" }, [
                    _vm.dataForm.isDangerous
                      ? _c("span", { staticClass: "title_suffix" }, [
                          _vm._v("超危"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { padding: "5px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        model: _vm.dataForm,
                        rules: _vm.rules,
                        "label-position": "right",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "危大工程名称",
                                    prop: "projectName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "255",
                                      placeholder: "请填写危大工程名称",
                                    },
                                    model: {
                                      value: _vm.dataForm.projectName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "projectName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.projectName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "危大工程类别",
                                    prop: "setTypeId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择危大工程类别",
                                      },
                                      on: { change: _vm.handleChangeSetType },
                                      model: {
                                        value: _vm.dataForm.setTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "setTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.setTypeId",
                                      },
                                    },
                                    _vm._l(_vm.typeList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "危大工程概况",
                                    prop: "projectOverview",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "255",
                                      placeholder: "请填写危大工程概况",
                                    },
                                    model: {
                                      value: _vm.dataForm.projectOverview,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "projectOverview",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.projectOverview",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "类别描述",
                                    prop: "setRemarIds",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择类别描述",
                                      },
                                      on: { change: _vm.handleChangRemark },
                                      model: {
                                        value: _vm.dataForm.setRemarIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "setRemarIds",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.setRemarIds",
                                      },
                                    },
                                    _vm._l(_vm.typeRemarkList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "施工部位",
                                    prop: "buildPartName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "buildPart",
                                    attrs: {
                                      title: _vm.dataForm.buildPartName,
                                      placeholder: "请选择施工部位",
                                    },
                                    on: { focus: _vm.handleChangeArea },
                                    model: {
                                      value: _vm.dataForm.buildPartName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "buildPartName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.buildPartName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "计划时间",
                                    prop: "planTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: _vm.dataForm.planTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "planTime", $$v)
                                      },
                                      expression: "dataForm.planTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "责任人",
                                    prop: "respUserName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "respUserName",
                                    attrs: {
                                      title: _vm.dataForm.respUserName,
                                      placeholder: "请选择责任人",
                                    },
                                    on: { focus: _vm.handleChangeUser },
                                    model: {
                                      value: _vm.dataForm.respUserName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "respUserName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.respUserName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "施工时间",
                                    prop: "buildTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: _vm.dataForm.buildTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "buildTime", $$v)
                                      },
                                      expression: "dataForm.buildTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.span } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "责任分包单位",
                                    prop: "respDeptName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "respDeptName",
                                    attrs: {
                                      title: _vm.dataForm.respDeptName,
                                      placeholder: "请选择责任分包单位",
                                      maxlength: "255",
                                    },
                                    on: { focus: _vm.handleChangeDept },
                                    model: {
                                      value: _vm.dataForm.respDeptName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "respDeptName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.respDeptName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "危大工程资料" } }),
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.dataForm,
                        "label-position": "right",
                        "label-width": "170px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "安全技术交底",
                                    prop: "skillInformationFlag",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "files_box" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": "1",
                                              "inactive-value": "0",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm
                                                  .skillInformationFlag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "skillInformationFlag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.skillInformationFlag",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "files_box_span" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataForm
                                                    .skillInformationFlag == 1
                                                    ? "已完成"
                                                    : "未完成"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
                                            "on-success": (
                                              response,
                                              file,
                                              fileList
                                            ) => {
                                              return _vm.uploadFileSuccess(
                                                response,
                                                file,
                                                fileList,
                                                "skillInformation"
                                              )
                                            },
                                            "on-remove": (file, fileList) => {
                                              return _vm.handleFileRemove(
                                                file,
                                                fileList,
                                                "skillInformation"
                                              )
                                            },
                                            multiple: "",
                                            "file-list": _vm.skillInformation,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "安全专项施工方案及附件",
                                    prop: "specialInformationFlag",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "files_box" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": "1",
                                              "inactive-value": "0",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm
                                                  .specialInformationFlag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "specialInformationFlag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.specialInformationFlag",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "files_box_span" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataForm
                                                    .specialInformationFlag == 1
                                                    ? "已完成"
                                                    : "未完成"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
                                            "on-success": (
                                              response,
                                              file,
                                              fileList
                                            ) => {
                                              return _vm.uploadFileSuccess(
                                                response,
                                                file,
                                                fileList,
                                                "specialInformation"
                                              )
                                            },
                                            "on-remove": (file, fileList) => {
                                              return _vm.handleFileRemove(
                                                file,
                                                fileList,
                                                "specialInformation"
                                              )
                                            },
                                            multiple: "",
                                            "file-list": _vm.specialInformation,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.dataForm.isDangerous
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "专项方案专家论证",
                                        prop: "skillDiscloseInformationFlag",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "files_box" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-value": "1",
                                                  "inactive-value": "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm
                                                      .skillDiscloseInformationFlag,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm,
                                                      "skillDiscloseInformationFlag",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm.skillDiscloseInformationFlag",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "files_box_span",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.dataForm
                                                        .skillDiscloseInformationFlag ==
                                                        1
                                                        ? "已完成"
                                                        : "未完成"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-upload",
                                            {
                                              staticClass: "upload-demo",
                                              attrs: {
                                                action:
                                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
                                                "on-success": (
                                                  response,
                                                  file,
                                                  fileList
                                                ) => {
                                                  return _vm.uploadFileSuccess(
                                                    response,
                                                    file,
                                                    fileList,
                                                    "skillDiscloseInformation"
                                                  )
                                                },
                                                "on-remove": (
                                                  file,
                                                  fileList
                                                ) => {
                                                  return _vm.handleFileRemove(
                                                    file,
                                                    fileList,
                                                    "skillDiscloseInformation"
                                                  )
                                                },
                                                multiple: "",
                                                "file-list":
                                                  _vm.constructionInformation,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                  },
                                                },
                                                [_vm._v("点击上传")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "专项施工方案交底",
                                    prop: "constructionInformationFlag",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "files_box" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": "1",
                                              "inactive-value": "0",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm
                                                  .constructionInformationFlag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "constructionInformationFlag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.constructionInformationFlag",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "files_box_span" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dataForm
                                                    .constructionInformationFlag ==
                                                    1
                                                    ? "已完成"
                                                    : "未完成"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
                                            "on-success": (
                                              response,
                                              file,
                                              fileList
                                            ) => {
                                              return _vm.uploadFileSuccess(
                                                response,
                                                file,
                                                fileList,
                                                "constructionInformation"
                                              )
                                            },
                                            "on-remove": (file, fileList) => {
                                              return _vm.handleFileRemove(
                                                file,
                                                fileList,
                                                "constructionInformation"
                                              )
                                            },
                                            multiple: "",
                                            "file-list":
                                              _vm.constructionInformation,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目区域选择",
            visible: _vm.areaDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.areaDialog = $event
            },
          },
        },
        [
          _c("prjArea", {
            ref: "prjArea",
            attrs: { dialogType: "" },
            on: { prjAreaCallback: _vm.prjAreaCallback },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _c("rectifyDataOrg", {
            ref: "rectifyDataOrg",
            attrs: { multiple: "", prjId: _vm.prjId },
            on: { callback: _vm.selectUserData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { parentDeptId: _vm.prjId, selection: true },
            on: { "select-all-data": _vm.selectDept },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }